<template>
    <svg class="logo" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.524 18.7427L23.4893 11.342C28.3295 8.57938 34.5184 10.2189 37.3111 15.0035L43 24.7461L4 47V29.9157C3.99967 27.6512 4.6024 25.4264 5.7476 23.4652C6.89281 21.5039 8.54014 19.8751 10.524 18.7427Z"
            :fill="`url(#${gradientId}_1)`"
        />

        <path
            d="M51.4722 44.2653L38.516 51.6581C33.6744 54.4207 27.4838 52.7809 24.6905 47.9959L19 38.2582L58 16V33.0917C57.9998 35.3566 57.3964 37.5815 56.2506 39.5429C55.1047 41.5043 53.4567 43.133 51.4722 44.2653Z"
            :fill="`url(#${gradientId}_2)`"
        />

        <path opacity="0.3" d="M43 24.5L20.4877 41L19 38.4911L43 24.5Z" fill="#123777" />

        <defs>
            <linearGradient :id="`${gradientId}_1`" x1="50.5392" y1="11.7456" x2="-2.16945" y2="37.2706" gradientUnits="userSpaceOnUse">
                <stop stop-color="#4A99ED" />

                <stop offset="1" stop-color="#83CCFF" />
            </linearGradient>

            <linearGradient :id="`${gradientId}_2`" x1="20.857" y1="47.9576" x2="65.0802" y2="24.6297" gradientUnits="userSpaceOnUse">
                <stop stop-color="#3A68DE" />

                <stop offset="1" stop-color="#5D8FEE" />
            </linearGradient>
        </defs>
    </svg>
</template>

<script>
    import { nanoid } from 'nanoid';

    export default {
        name: 'Logo',
        computed: {
            gradientId: () => `logo_gradient_${nanoid()}`,
        },
    };
</script>

<style lang="scss" scoped>
    .logo {
        flex: 0 0 auto;
        display: block;
        width: 3.6rem;
        height: 3.6rem;
    }

    @media screen and (min-width: 75em) {
        .logo {
            width: 4.7rem;
            height: 4.7rem;
        }
    }

    @media screen and (min-width: 120em) {
        .logo {
            width: 5.8rem;
            height: 5.8rem;
        }
    }
</style>
