<template>
    <div ref="select" :class="{ select: true, select_disabled: disabled }" v-click-outside="close">
        <input type="hidden" :value="value" />

        <div ref="value" :class="valueClass" @click="dropdown.opened = !dropdown.opened">
            <p v-if="!selectedOption">{{ placeholder }}</p>

            <template v-else>
                <img v-if="selectedOption.image" class="select__value-image" :src="selectedOption.image" :alt="processLabel(selectedOption.label)" />

                <p v-if="selectedOption.label">{{ processLabel(selectedOption.label) }}</p>
            </template>

            <angle-down-icon class="select__value-icon" />
        </div>

        <div ref="options" :class="optionsClass">
            <div v-for="(option, index) in optionsList" :class="optionClass(option, value)" :key="index" @click="selectOption(option)">
                <img v-if="option.image" class="select__option-image" :src="option.image" :alt="processLabel(option.label)" />
                <p v-if="option.label">{{ processLabel(option.label) }}</p>
            </div>
        </div>
    </div>
</template>

<script>
    import _ from 'underscore';
    import AngleDownIcon from '@/components/icons/AngleDownIcon';

    export default {
        name: 'SelectComponent',
        components: {
            AngleDownIcon,
        },
        props: {
            disabled: {
                type: Boolean,
                default: false,
            },
            options: {
                type: Array,
                default: () => [],
            },
            outlined: {
                type: Boolean,
                default: false,
            },
            placeholder: String,
            position: {
                type: String,
                validator: value => [null, 'down', 'up'].includes(value),
            },
            size: {
                type: String,
                default: 'medium',
                validator: value => ['large', 'medium', 'small'].includes(value),
            },
            value: [Number, String],
            withoutSelected: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            isOpened() {
                return this.dropdown.opened && !!this.optionsList.length;
            },
            optionsClass() {
                return [
                    'select__options',
                    this.dropdown.position ? `select__options_${this.dropdown.position}` : null,
                    !this.isOpened ? 'select__options_hidden' : null,
                ].filter(Boolean);
            },
            optionsList() {
                return this.withoutSelected ? this.options.filter(option => option.value !== this.value) : this.options;
            },
            selectedOption() {
                return this.options.filter(option => option.value === this.value)[0] || null;
            },
            valueClass() {
                return [
                    'select__value',
                    `select__value_${this.size}`,
                    this.outlined ? 'select__value_outlined' : null,
                    this.dropdown.opened ? 'select__value_opened' : null,
                    this.dropdown.position ? `select__value_${this.dropdown.position}` : null,
                    !!this.selectedOption ? 'select__value_selected' : null,
                ].filter(Boolean);
            },
        },
        methods: {
            close() {
                this.dropdown.opened = false;
                this.dropdown.position = this.position || null;
            },
            initIntersectionObserver() {
                if (!this.position) {
                    const viewportIO = new IntersectionObserver(
                        entries =>
                            entries.forEach(
                                entry =>
                                    (this.dropdown.position = entry.boundingClientRect.height ? (entry.intersectionRatio < 1 ? 'up' : 'down') : null)
                            ),
                        {
                            root: this.$refs.select.closest('.app-layout__content') || this.$refs.select.closest('.scroll-container__content'),
                        }
                    );

                    viewportIO.observe(this.$refs.options);
                }
            },
            optionClass(option, value) {
                return ['select__option', `select__option_${this.size}`, option.value === value ? 'select__option_selected' : null].filter(Boolean);
            },
            processLabel: label => (_.isFunction(label) ? label() : label),
            selectOption(option) {
                this.close();
                this.$emit('input', option.value);
            },
        },
        data() {
            return {
                dropdown: {
                    opened: false,
                    position: this.position || null,
                },
            };
        },
        mounted() {
            this.initIntersectionObserver();
        },
    };
</script>

<style lang="scss" scoped>
    .select__value {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-width: 0;
        height: 100%;
        border-radius: 0.8rem;
        background-color: #161c28;
        cursor: pointer;
        transition: background-color 0.3s;
        text-overflow: ellipsis;
        overflow: hidden;

        p,
        span {
            display: block;
            width: 100%;
            font-family: 'Gotham Book', sans-serif;
            font-weight: 400;
            color: #585f75;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            transition: color 0.3s;
        }

        img + p,
        img + span {
            margin-left: 1rem;
        }

        &-icon {
            flex-shrink: 0;
            width: 0.8rem;
            fill: #585f75;
            transition: fill 0.3s;
        }

        &_opened &-icon {
            transform: translate(0, -50%) rotate(180deg);
        }

        &_selected &-icon {
            fill: #ccd1de;
        }

        &_selected p,
        &_selected span {
            color: #ccd1de;
        }

        &_medium &-icon {
            margin-left: 2rem;
        }

        &_small &-icon {
            margin-left: 1rem;
        }

        &_outlined {
            background-color: transparent;
            border: 1px solid #1a2030;
        }

        &_opened {
            background-color: #1b2131;

            &.select__value_up {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }

            &.select__value_down {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }

    .select__options {
        position: absolute;
        left: 0;
        width: 100%;
        border-radius: 0.8rem;
        background-color: #1b2131;
        z-index: 9999;
        overflow: hidden;
        visibility: hidden;

        &_up {
            bottom: 100%;
            padding-bottom: 0;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            visibility: visible;
        }

        &_down {
            top: 100%;
            padding-top: 0;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            visibility: visible;
        }

        &_hidden {
            display: none;
        }
    }

    .select__option {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 1.4rem;
        cursor: pointer;
        transition: background-color 0.3s, opacity 0.3s;
        text-overflow: ellipsis;
        overflow: hidden;

        p,
        span {
            display: block;
            width: 100%;
            font-family: 'Gotham Book', sans-serif;
            font-weight: 400;
            color: #ccd1de;
            text-overflow: ellipsis;
            overflow: hidden;
            transition: color 0.3s;
        }

        img + p,
        img + span {
            margin-left: 1rem;
        }

        &:hover {
            background-color: #262c41;
            opacity: 0.75;
        }

        &_selected {
            opacity: 0.2;
            pointer-events: none;
        }
    }

    .select__value,
    .select__option {
        &_medium {
            height: 4.6rem;
            padding: 0 2rem;

            p {
                font-size: 1.2rem;
            }
        }

        &_small {
            height: 3.6rem;
            padding: 0 1rem;

            p {
                font-size: 1.2rem;
            }
        }
    }

    .select {
        position: relative;

        &_disabled {
            pointer-events: none;
        }

        &_disabled &__value p,
        &_disabled &__value span {
            color: #585f75;
        }

        &_disabled &__value-icon {
            fill: #585f75;
        }
    }

    @media screen and (min-width: 22.5em) {
        .select__value,
        .select__option {
            &_medium {
                height: 4.8rem;

                p {
                    font-size: 1.3rem;
                }
            }
        }
    }

    @media screen and (min-width: 120em) {
        .select__value,
        .select__option {
            &_medium {
                height: 5.2rem;

                p {
                    font-size: 1.5rem;
                }
            }

            &_small {
                height: 4.4rem;
                padding: 0 1.5rem;

                p {
                    font-size: 1.3rem;
                }
            }
        }

        .select__value {
            &_small &-icon {
                right: 1.5rem;
            }
        }
    }
</style>
