<template>
    <div :class="{ 'scroll-container': true, 'scroll-container_horizontal': scrollX, 'scroll-container_vertical': scrollY }" ref="container">
        <div class="scroll-container__content" ref="content">
            <slot />
        </div>

        <div v-if="scrollY" class="scroll-container__vertical-scrollbar" ref="verticalBar">
            <div class="scroll-container__vertical-indicator" ref="verticalIndicator" />
        </div>

        <div v-if="scrollX" class="scroll-container__horizontal-scrollbar" ref="horizontalBar">
            <div class="scroll-container__horizontal-indicator" ref="horizontalIndicator" />
        </div>
    </div>
</template>

<script>
    import BetterScroll from '@better-scroll/core';
    import ScrollBar from '@better-scroll/scroll-bar';
    import MouseWheel from '@better-scroll/mouse-wheel';

    BetterScroll.use(ScrollBar);
    BetterScroll.use(MouseWheel);

    export default {
        name: 'ScrollContainer',
        props: {
            type: {
                type: String,
                default: 'vertical',
                validator: value => ['vertical', 'horizontal'].includes(value),
            },
        },
        computed: {
            scrollX() {
                return 'horizontal' === this.type;
            },
            scrollY() {
                return 'vertical' === this.type;
            },
        },
        methods: {
            refresh() {
                if (this.scrollX && this.$refs.horizontalIndicator) {
                    this.$refs.horizontalIndicator.style.width = `${(this.$refs.container.clientWidth / this.$refs.content.clientWidth) * 100}%`;
                    this.bs.options.stopPropagation = parseInt(this.$refs.horizontalIndicator.style.width) < 100;
                }

                if (this.scrollY && this.$refs.verticalIndicator) {
                    this.$refs.verticalIndicator.style.height = `${(this.$refs.container.clientHeight / this.$refs.content.clientHeight) * 100}%`;
                    this.bs.options.stopPropagation = parseInt(this.$refs.verticalIndicator.style.height) < 100;
                }

                this.bs.refresh();
            },
        },
        data() {
            return {
                bs: null,
            };
        },
        mounted() {
            const isNested = this.$refs.container.parentNode.closest('.scroll-container');

            this.bs = new BetterScroll(this.$refs.container, {
                bounce: false,
                click: !isNested,
                tap: !isNested,
                mouseWheel: {
                    easeTime: 850,
                },
                preventDefault: false,
                scrollX: this.scrollX,
                scrollY: this.scrollY,
                scrollbar: {
                    customElements: this.scrollX ? [this.$refs.horizontalBar] : [this.$refs.verticalBar],
                    fade: false,
                    interactive: true,
                    scrollbarTrackClickable: true,
                },
            });

            const resizeObserver = new ResizeObserver(() => this.refresh());

            resizeObserver.observe(this.$refs.container);
            resizeObserver.observe(this.$refs.content);
        },
    };
</script>

<style lang="scss" scoped>
    .scroll-container {
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;

        &_horizontal > &__content {
            display: inline-block;
            min-width: 100%;
        }

        &_vertical > &__content {
            min-height: 100%;
        }
    }

    .scroll-container__content {
        position: relative;
        min-height: 100%;
        will-change: transform;
    }

    .scroll-container__vertical-scrollbar,
    .scroll-container__horizontal-scrollbar {
        position: absolute;
        border-radius: 0.3rem;
        background-color: #191f30;
    }

    .scroll-container__vertical-indicator,
    .scroll-container__horizontal-indicator {
        border-radius: 0.3rem;
        background-color: #293249;
    }

    .scroll-container__vertical-scrollbar {
        top: 0;
        right: 0;
        width: 0.3rem;
        height: 100%;
    }

    .scroll-container__vertical-indicator {
        width: 100%;
    }

    .scroll-container__horizontal-scrollbar {
        left: 0;
        bottom: 0;
        width: 100%;
        height: 0.3rem;
    }

    .scroll-container__horizontal-indicator {
        height: 100%;
    }
</style>
