<template>
    <svg viewBox="0 0 8 6" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 6L8 0H0L4 6Z" />
    </svg>
</template>

<script>
    export default {
        name: 'AngleDownIcon',
    };
</script>
