<template>
    <select-component
        v-model="$i18n.locale"
        v-on="$listeners"
        v-bind="$attrs"
        :class="{ 'locale-select': true, 'locale-select_minimal': minimal }"
        :options="localeOptions"
        without-selected
    />
</template>

<script>
    import SelectComponent from '@/components/inputs/SelectComponent';

    export default {
        name: 'LocaleSelect',
        components: {
            SelectComponent,
        },
        props: {
            flags: {
                type: Boolean,
                default: true,
            },
            labels: {
                type: Boolean,
                default: true,
            },
            localize: {
                type: Boolean,
                default: true,
            },
            minimal: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            localeOptions() {
                return this.$i18n.availableLocales.map(locale =>
                    Object.assign(
                        { value: locale },
                        this.flags ? { image: this.image(locale) } : {},
                        this.labels ? { label: this.label(locale) } : {}
                    )
                );
            },
        },
        methods: {
            image: locale => require(`@/assets/images/flag-${locale}.svg`),
            label(locale) {
                return this.localize ? this.$t(`locale.${locale}`) : locale.toUpperCase();
            },
        },
    };
</script>

<style lang="scss" scoped>
    .locale-select {
        &_minimal::v-deep .select__value-icon {
            display: none;
        }

        ::v-deep {
            .select__option p,
            .select__value p {
                font-size: 1.3rem;
            }

            .select__option-image,
            .select__value-image {
                width: 2.6rem;
                border-radius: 0.4rem;
            }
        }
    }
</style>
